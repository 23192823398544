// https://api.prm.sis.lt/docs
export const getServices = (remoteAdapter, storageAdapter) => {
  return {
    // either pubic of gw
    initPayment: (prmId, url = null, token = null) => {
      const requestOpts = token ? {
        headers: {
          Authorization: 'Bearer ' + token
        }
      } : {};
      return remoteAdapter.post(url ? url : `${AUTH_SERVICE_URL}/public/prm/${prmId}/init`, {
      }, requestOpts);
    },
    // public
    getPrm: (prmId, url = null, token = null) => {
      const requestOpts = token ? {
        headers: {
          Authorization: 'Bearer ' + token
        }
      } : {};
      return remoteAdapter.get(url ? url : `${AUTH_SERVICE_URL}/public/prm/${prmId}`, requestOpts);
    },
    // public
    validateToken: (tokenB64) => {
      // TODO validate url
      return remoteAdapter.post(`${AUTH_SERVICE_URL}/public/validate-token`, {
        paymentToken: tokenB64
      })
    },
    // gw
    getDocument: (token) => {
      return remoteAdapter.get(`${AUTH_SERVICE_URL}/gw/document`, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
    },
    // gw
    getQrCode: (token) => {
      return remoteAdapter.get(`${AUTH_SERVICE_URL}/gw/qr`, {
        params: {
          gateway: 'DEFAULT'
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
    },
    // gw
    getStatus: (token) => {
      return remoteAdapter.get(`${AUTH_SERVICE_URL}/gw/status`, {
        params: {
          waitForStatuses: [
            'created', 'started', 'accepted', 'rejected', 'received', 'payed', 'canceled'
          ],
          timeout: 30
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
    },
    // gw
    getPrmGw: (token) => {
      const requestOpts = token ? {
        headers: {
          Authorization: 'Bearer ' + token
        }
      } : {};
      return remoteAdapter.get(`${AUTH_SERVICE_URL}/gw/prm`, requestOpts);
    },
  }
};