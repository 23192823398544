import { assign } from 'lodash';
import { call, take } from 'redux-saga/effects';
import createRouter from 'router5';
import browserPlugin from 'router5-plugin-browser';
import { actionTypes, selectors } from 'src/auth/store.js';
import globals from 'src/init.js';

// Auth middleware
const authMiddleware = (router, state) => (toState, fromState, done) => {
  const storeState = state.store.getState();
  const isAuthrized = selectors.isAuthrized(storeState);
  const publicRoutes = {
    [globals.RouteNames.LOGIN]: true,
    [globals.RouteNames.ERROR]: true
  };
  return (isAuthrized || toState.name.split('.', 1) in publicRoutes)
    // App is in authorised state OR route is subroute of public route
    ? (isAuthrized && toState.name.split('.', 1) in { [globals.RouteNames.LOGIN]: true })
      // App is in authorised state AND route is subroute of login
      ? done({
        redirect: {
          name: globals.RouteNames.HOME,
          params: {
            replace: true,
          }
        }
      })
      // App is in authorised state AND route is NOT subroute of public route
      : done()
    // App is in unauthrised state AND route is NOT subroute of public route
    : done({
      redirect: {
        name: globals.RouteNames.LOGIN,
        params: {
          next: {
            name: toState.name,
            params: toState.params
          }
        }
      }
    });
};


export async function initialiseRouter(modules) {
  const moduleRoutes = await Promise.all(modules.map(moduleName => {
    return import(/* webpackMode: "eager" */`src/${moduleName}/routes.js`).catch(error => { });
  }));

  const initialRoutes = {
    ERROR: 'error',
    SERVICE_ERROR: 'error.serviceError',
    NOT_FOUND: 'error.notFound',
    APPLICATION_ERROR: 'error.applicationError',
  };

  const router = moduleRoutes.reduce((accumulatedRoutes, module) => {
    if (module) {
      assign(accumulatedRoutes.routeNames, module.routeNames);
      accumulatedRoutes.routeDefs.push(module.default);
      assign(accumulatedRoutes.moduleMaps, module.moduleMap)
    }
    return accumulatedRoutes;
  }, {
    routeNames: initialRoutes,
    routeDefs: [
      {
        name: initialRoutes.ERROR,
        path: '/error',
      },
      {
        name: initialRoutes.SERVICE_ERROR,
        path: '/service',
      },
      {
        name: initialRoutes.NOT_FOUND,
        path: '/not_found',
      },
      {
        name: initialRoutes.APPLICATION_ERROR,
        path: '/application',
      },
    ],
    moduleMaps: {
      serviceError: () => import('src/common/components/errors/500.js'),
      notFound: () => import('src/common/components/errors/404.js'),
      applicationError: () => import('src/common/components/errors/AppError.js'),
    }
  });

  // Initialise router
  const routerInstance = createRouter(router.routeDefs, {
    allowNotFound: false,
    useTrailingSlash: false
  });
  routerInstance.useMiddleware(authMiddleware);
  routerInstance.usePlugin(browserPlugin());
  router.routerInstance = routerInstance;

  return router;
};


export function* startRouter() {
  const readyAction = yield take(actionTypes.READY);
  if (readyAction && !globals.Router.isStarted()) {
    yield call(globals.Router.start);
  }
}