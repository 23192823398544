import { all, call, put, select, delay, take, race } from 'redux-saga/effects';
import produce from 'immer';
import { sagaRunner, responseUnwrap } from 'src/store.js';
import { get, last, first } from 'lodash';
import globals from 'src/init.js';
import { actions as commonActions } from 'src/common/store.js';
import {
  actions as routerActions,
  actionTypes as routerActioTypes
} from 'redux-router5';
import { selectors as rootSelectors } from 'src/root/store.js';
import { fromBinary, arrayBufferToB64 } from 'src/common/tools.js';

// Declare all action types
export const actionTypes = {
  START_PRM_WATCH: 'cpgw/START_PRM_WATCH',
  STOP_PRM_WATCH: 'cpgw/STOP_PRM_WATCH',
  SET_STATUS: 'cpgw/SET_STATUS',
  SET_PRM: 'cpgw/SET_PRM',
  UPDATE_PRM: 'cpgw/SET_PRM',
  INIT_PAYMENT: 'cpgw/INIT_PAYMENT',
  // TODO extract into url auth package
  SET_TOKEN: 'auth/SET_TOKEN',
};


// Actions
export const actions = {
  initPayment: (prmId) => ({
    type: actionTypes.INIT_PAYMENT,
    payload: { prmId }
  })
};

// Declare selectors
export const selectors = {
  prm: state => get(state, 'currentPrm.prm'),
  token: state => get(state, 'currentPrm.paymentToken')
};

export const reducer = {
  currentPrm: produce((currentPrm, action) => {
    const { type, payload } = action;
    if (type == actionTypes.SET_PRM) {
      currentPrm.prm = payload;
    } else if (type == actionTypes.UPDATE_PRM) {
      currentPrm.prm = {
        ...payload,
        ...currentPrm.prm,
      };
    } else if (type == actionTypes.SET_STATUS) {
      currentPrm.prm.status = payload.status;
    } else if (type == actionTypes.SET_TOKEN) {
      currentPrm.paymentToken = payload;
    }
  }, {})
};

// Initial state
export const initial = {
  currentPrm: {
    prm: {},
    paymentToken: null
  }
};

// Sagas
export function* sagas() {
  yield all([
    consumeTokenSaga(),
    sagaRunner(prmWatcherSaga),
    sagaRunner(payNowSaga),
    sagaRunner(statusWatcherSaga)
  ]);
}

function* consumeTokenSaga() {
  const navigationAction = yield take(routerActioTypes.TRANSITION_SUCCESS);
  const prmId = get(navigationAction, 'payload.route.params.prmId');
  const paymentToken = get(navigationAction, 'payload.route.params.paymentToken');
  if (paymentToken) {
    localStorage.setItem("token", paymentToken);
  }
  yield call(validateToken, prmId)
}

const validStatuses = {
  PAYMENT_TOKEN_VALID: true,
  PAYMENT_IN_PROGRESS: true
};

function* validateToken(prmId) {
  const tokenBase64 = localStorage.getItem("token");
  // urlBase64
  if (tokenBase64) {
    try {
      const tokenBodyBase64 = (
        tokenBase64.split(".")[1]).replace("_", "/").replace("-", "+");
      const token = JSON.parse(fromBinary(atob(tokenBodyBase64)));
      if (token.id === prmId) {
        let currentTime = (new Date().getTime()) / 1000;
        if (currentTime > token.exp) {
          yield call(errorSaga, 'expired');
        }
        // TODO api will return invalid token if prm is started
        // const validityResponse = yield call(globals.Services.cpGateway.validateToken, tokenBase64);
        // if (validityResponse.data.status in validStatuses && validStatuses[validityResponse.data.status]) {
        //   yield call(gwPrmLoaderSaga, prmId, tokenBase64);
        // } else {
        //   yield call(errorSaga, 'invalid');
        // }
        yield put({ type: actionTypes.SET_TOKEN, payload: token });
      } else {
        // token prm id differs from url, most likely token from previous payment, 
        // continue with public saga
        yield call(publicPrmLoaderSaga);
      }
    } catch (error) {
      console.error(error)
      yield call(errorSaga, 'malformed', error);
    }
  } else {
    // no token in store, continue with public saga
    yield call(publicPrmLoaderSaga, prmId);
  }
}

function* publicPrmLoaderSaga(prmId) {
  const prmResponse = yield responseUnwrap(call(globals.Services.cpGateway.getPrm, prmId));
  if (prmResponse.status == 200 && prmResponse.data) {
    yield put({
      type: actionTypes.SET_PRM, payload: {
        prmId: prmId,
        ...prmResponse.data
      }
    });
  } else {
    console.error(prmResponse)
    // TODO report error
  }
}

function* gwPrmLoaderSaga(prmId, paymentToken) {
  const prmResponse = yield responseUnwrap(call(globals.Services.cpGateway.getPrmGw, paymentToken));
  if (prmResponse.status == 200 && prmResponse.data) {
    yield put({
      type: actionTypes.SET_PRM, payload: {
        prmId: prmId,
        ...prmResponse.data
      }
    });
    // document
    const documentResponse = yield responseUnwrap(call(globals.Services.cpGateway.getDocument, paymentToken));
    if (documentResponse.data) {
      yield put({ type: actionTypes.UPDATE_PRM, payload: { documentBytes: documentResponse.data } })
    } else {
      console.error(documentResponse);
    }
    // qr
    const qrResponse = yield responseUnwrap(call(globals.Services.cpGateway.getQrCode, paymentToken));
    if (documentResponse.data) {
      const qrCode = arrayBufferToB64(qrResponse.data);
      yield put({ type: actionTypes.UPDATE_PRM, payload: { qrCode } })
    } else {
      console.error(qrResponse);
    }
  } else {
    yield call(errorSaga, 'getPrmError', prmResponse)
    console.error(prmResponse)
  }
}

function* errorSaga(errorType, error) {
  yield put(commonActions.showModal({
    title: errorType,
    type: 'error',
    message: JSON.stringify(error),
  }));
}

function* payNowSaga() {
  const payAction = yield take(actionTypes.INIT_PAYMENT);
  const initPaymentResponse = yield responseUnwrap(call(
    globals.Services.cpGateway.initPayment, payAction.payload.prmId
  ));
  if (initPaymentResponse.status == 200 && initPaymentResponse.data) {
    window.location = initPaymentResponse.data.url;
  } else {
    // TODO error
  }
}

function* prmWatcherSaga() {
  const startAction = yield take(actionTypes.START_PRM_WATCH);
  const token = yield select(selectors.token);
  while (true) {
    const statusResponse = yield responseUnwrap(call(
      globals.Services.cpGateway.getStatus, token
    ));
    if ("error" in statusResponse) {
      // TODO allow some failures
      console.error(statusResponse.error)
    } else {
      yield put({ type: actionTypes.SET_STATUS, payload: statusResponse.data });
    }
    const [delayPassed, stopped] = yield race([
      delay(30 * 1000),
      take(actionTypes.STOP_PRM_WATCH)
    ]);
    if (stopped) {
      break;
    }
  }
}

function* statusWatcherSaga() {
  const statusAction = yield take([
    actionTypes.SET_STATUS, actionTypes.SET_PRM
  ]);
  const terminalStatuses = {
    payed: true, accepted: true, canceled: false, rejected: false
  };
  const status = statusAction.payload.status;
  if (status in terminalStatuses) {
    if (terminalStatuses[status]) {
      yield put(commonActions.showModal({
        title: `Payment successful`,
        type: 'success',
        message: `Status: ${status}`,
      }));
    } else {
      yield put(commonActions.showModal({
        title: 'Payment failed',
        type: 'warning',
        message: `Status: ${status}`,
      }));
      //yield delay(4 * 1000);
      //yield put(commonActions.hideModal());
    }
    yield put({ type: actionTypes.STOP_PRM_WATCH });
  } else if (status == "created") {
    yield put({ type: actionTypes.START_PRM_WATCH });
  }
}
