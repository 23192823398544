import React from 'react';
import ReactDom from 'react-dom/client';
import { Provider } from 'react-redux';
import globals, { initApp } from 'src/init.js';
import 'src/polyfills.js';
import RootConnected from 'src/root/index.js';
import 'antd/dist/antd.css'; // 'antd/dist/antd.less'
// import '@sis-lab/web-ui-components/dist/style.css';
import "src/cpGateway/tmp.css";
import Modal from 'src/common/components/Modal.js';

// TODO vite?
window.DataStream = null;

// App attachment node
const rootNode = document.getElementsByTagName('body')[0];

// App modules
const modules = [
  'common', 'cpGateway', 'root'
];

// React
const root = ReactDom.createRoot(rootNode);
initApp(modules).then(() => {
  root.render(
    <Provider store={globals.Store}>
      <RootConnected moduleMaps={globals.ModuleMaps} />
    </Provider>
  );
});