export const routeNames = {
  INDEX: 'index',
  // PRM: 'prm',
  ERROR: 'prmError'
}

// https://router5.js.org/guides/defining-routes
const routes = [
  // {
  //   name: routeNames.INDEX,
  //   path: '/',
  // },
  {
    name: routeNames.INDEX,
    path: '/?:paymentToken&:prmId',
  },
  {
    name: routeNames.ERROR,
    path: '/prmError?:errorCode',
  }
];

export const moduleMap = {
  [routeNames.INDEX]: () => import('src/cpGateway/Index.js'),
  // [routeNames.PRM]: () => import('src/cpGateway/Index.js'),
  [routeNames.ERROR]: () => import('src/cpGateway/Error.js')
};

export default routes;